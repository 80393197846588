import { SausagepediaComponentProps } from "./types";

function generateWordListUrl(theme: string, wordList: string[]): string {
  const themeArg = encodeURIComponent(theme);
  const wordListArg = encodeURIComponent(btoa(wordList.join(",")));
  return `?theme=${themeArg}&words=${wordListArg}`;
}

const SpellingPracticeLink: React.FunctionComponent<
  SausagepediaComponentProps
> = ({ sausagepediaComponentMetadata }: SausagepediaComponentProps) => {
  const emptyComponent = <div />;
  if (!sausagepediaComponentMetadata) return emptyComponent;
  const spellingListElement = document.getElementById(
    sausagepediaComponentMetadata,
  );
  if (!spellingListElement) return emptyComponent;
  const spellingListThemeElement = spellingListElement.querySelector(
    "span.spelling-list__theme",
  ) as HTMLSpanElement;
  const spellingListTheme = spellingListThemeElement?.innerText ?? "";
  const spellingListItemElements = Array.from(
    spellingListElement.querySelectorAll(
      "ul.spelling-list__word-list > li",
    ) as NodeListOf<HTMLLIElement>,
  );
  if (!spellingListItemElements) return emptyComponent;
  const spellingWords = spellingListItemElements.map(
    (x) => x.getAttribute("data-spelling-word") || x.innerText,
  );
  const url = generateWordListUrl(spellingListTheme, spellingWords);
  return (
    <a href={`/spell_or_shark/${url}`}>
      Practice in Spell or Shark
    </a>
  );
};

export default SpellingPracticeLink;
