import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import SiteNavigationBar from "./SiteNavigationBar";
import SiteNavigation from "./SiteNavigation";
import RecentlyUpdatedPages from "./RecentlyUpdatedPages";
import SpellingPracticeLink from "./SpellingPracticeLink";
import { SausagepediaComponentProps } from "./types";

interface ComponentLibrary {
  [key: string]: React.FunctionComponent<SausagepediaComponentProps>;
}

const components: ComponentLibrary = {
  "site-navigation-bar": SiteNavigationBar,
  "site-navigation": SiteNavigation,
  "recently-updated-pages": RecentlyUpdatedPages,
  "spelling-list__practice-link": SpellingPracticeLink,
};

Object.keys(components).forEach((componentKey) => {
  const elements = document.getElementsByClassName(`${componentKey}`);
  Array.from(elements).forEach((element) => {
    const sausagepediaComponentMetadata = element.getAttribute(
      "data-sausagepedia-component-metadata",
    );
    const Component = components[componentKey];
    const componentRoot = ReactDOM.createRoot(element);
    componentRoot.render(
      <React.StrictMode>
        <Component
          sausagepediaComponentMetadata={
            sausagepediaComponentMetadata || undefined
          }
        />
      </React.StrictMode>,
    );
  });
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
