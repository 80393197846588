import { useEffect, useState } from "react";
import {
  SiteMeta
} from "./site-meta";
import {
  SausagepediaComponentProps,
} from "./types";

const SiteNavigation: React.FunctionComponent<SausagepediaComponentProps> = ({
  sausagepediaComponentMetadata: topic,
}: SausagepediaComponentProps) => {
  const [siteMeta, setSiteMeta] = useState<SiteMeta | null>(null);
  useEffect(() => {
    SiteMeta.fetch().then((response) => setSiteMeta(response));
  }, []);
  // map of topics
  if (!topic) {
    return (
      <div>
        <ul className="topic-list__list">
          {siteMeta && Object.keys(siteMeta?.siteMap).filter(topic => topic).map((topic) => (
            <li className="topic-list__list-item" key={topic}>
              <a href={`/${topic}/`}>{siteMeta?.nameForTopic(topic)}</a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  // map of pages under topic
  const pages = siteMeta?.siteMap[topic];
  if (!pages) return <div />;
  return (
    <div>
      <ul className="page-list__list">
        {pages.filter(page => page.url !== "index.html").map((page) => (
          <li className="page-list__list-item" key={`/${topic}/${page.url}`}>
            <a href={`/${topic}/${page.url}`}>{page.title ?? page.url}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SiteNavigation;
