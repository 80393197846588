import {PageModel} from "./types";

export function addSpellingListToPracticeRoster(theme: string, wordList: string[]) {
  const practiceRosterStr = sessionStorage.getItem("spelling-practice-roster") || null;
  const practiceRoster = practiceRosterStr ? JSON.parse(practiceRosterStr) : [];
  practiceRoster.push({theme, wordList})
  sessionStorage.setItem("spelling-practice-roster", JSON.stringify(practiceRoster));
}

export function comparePageModels(a: PageModel, b: PageModel) {
  return a.title.localeCompare(b.title);
}