import { useEffect, useState } from "react";
import { SiteMeta } from "./site-meta";
import { PageModel, SausagepediaComponentProps } from "./types";
import R from "./resources";

interface PageWithTopic {
  page: PageModel;
  topic: string;
}

const maxRecentPages = 10;

const RecentlyUpdatedPages: React.FunctionComponent<
  SausagepediaComponentProps
> = ({ sausagepediaComponentMetadata: topic }: SausagepediaComponentProps) => {
  const [siteMeta, setSiteMeta] = useState<SiteMeta | null>(null);
  useEffect(() => {
    SiteMeta.fetch().then((response) => setSiteMeta(response));
  }, []);

  if (!siteMeta) return <div />;

  const topics = topic ? [topic] : Object.keys(siteMeta.siteMap);
  const allPagesWithTopics = topics.reduce<PageWithTopic[]>(
    (pagesWithTopicAcc, currentTopic) => {
      const pagesWithTopicForTopic = siteMeta?.siteMap[currentTopic].map(
        (page) => ({ page, topic: currentTopic }),
      );
      return [...pagesWithTopicAcc, ...pagesWithTopicForTopic];
    },
    [],
  );
  const recentPagesWithTopics = allPagesWithTopics
    .sort((a, b) =>
      b.page.lastUpdatedDateTime.localeCompare(a.page.lastUpdatedDateTime),
    )
    .filter((_x, i) => i < maxRecentPages);
  if (!recentPagesWithTopics) return <div />;
  return (
    <div>
      <ul className="page-list__list">
        {recentPagesWithTopics.map((pageWithTopic) => (
          <li
            className="page-list__list-item"
            key={`/${pageWithTopic.topic}/${pageWithTopic.page.url}`}
          >
            <a href={`/${pageWithTopic.topic}/${pageWithTopic.page.url}`}>
              {pageWithTopic.page.title ?? pageWithTopic.page.url}
            </a>
            &nbsp;({R.Label.PageUpdatedLabel}{" "}
            {new Date(
              pageWithTopic.page.lastUpdatedDateTime,
            ).toLocaleDateString()}
            ) | {siteMeta?.nameForTopic(pageWithTopic.topic)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentlyUpdatedPages;
