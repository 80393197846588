enum Label {
    TopicsMenuTitle = "Topics",
    PagesMenuTitle = "Pages",
    PageUpdatedLabel = "updated"
}

const resources = {
  Label,
};

export default resources;
