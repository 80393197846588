import {SiteMapModel} from "./types";

export class SiteMeta {
  private _siteName = "A Website";
  private readonly topicNames: {[topic: string]: string} = {};
  private constructor(public siteMap: SiteMapModel) {
    // determine topicNames from siteMap
    this.topicNames = Object.keys(siteMap).reduce((namesForTopics, topic) => {
      const pages = siteMap[topic];
      const indexPage = pages.find(page => page.url === "index.html");
      const topicName = indexPage ? indexPage.title : topic;
      if (!topic) this._siteName = topicName;
      return { ...namesForTopics, [topic]: topicName }
    }, {});
  }

  static async fetch() {
    const siteMetaInfo = await (await fetch("/_site_meta.json")).json();
    return new SiteMeta(siteMetaInfo);
  }

  siteName() {
    return this._siteName;
  }

  nameForTopic(topic: string) {
    if (!this.topicNames || !(topic in this.topicNames)) return topic;
    return this.topicNames[topic];
  }
}
